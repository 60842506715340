// i18next-extract-mark-ns-start legal-billing-software

import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {OrderedList} from 'components/List';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import styled from 'styled-components';
import {Flex} from 'components/Flex';
import React from 'react';
import {DownloadOnAppleStore, IosDownloadLink} from 'components/DownloadOnAppleStore';
import {AndroidDownloadLink, DownloadOnGooglePlay} from 'components/DownloadOnGooglePlay';
import Bold from 'components/Bold';
import {MoneiVideoSection} from 'components/landings/MoneiVideoSection';
import {CompatibleWith} from 'components/monei-pay/CompatibleWith';
import IndexBackground from 'components/monei-pay/IndexBackground';
import {InternalPageLink} from 'components/links/Pages';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import cards from 'images/credit-cards.svg';
import mobile_payment from 'images/mobile_payment.svg';
import money from 'images/money.svg';
import speechbubble from 'images/speechbubble.svg';
import security from 'images/security.svg';
import person from 'images/person.svg';
import {SignUpLink} from 'components/links/Other';
import {StyledDiv} from 'components/StyledSx';
import {Background} from 'components/Background';
import {QrGroup} from 'components/monei-pay/QrGroup';
import {RotatedScreenshots} from 'components/monei-pay/RotatedScreenshots';
import {SupportLink} from 'components/links/Support';
import {BlogLink} from 'components/links/Blog';
import {MainTitleContent} from 'components/monei-pay/MainTitleContent';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import integrated_payments_header_image from 'images/integrated_payments_header_image.png';
import {ButtonLink} from 'components/Button';
import {AnchorLink} from 'components/AnchorLink';

const CardWrapper = styled.div`
  max-width: 230px;
`;

const CardImage = styled.img`
  position: absolute;
`;

const RetailPosSytem: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        title={t('Best Legal Billing Software')}
        description={t(
          'Use the best legal billing software to digitize your lawyer payments and charge clients from your smartphone, computer, or tablet with MONEI Pay.'
        )}
      />

      <IndexBackground>
        <Content>
          <Section columnLg>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Improve payments with the best legal billing software</Trans>
              </SectionHeader>
              <Trans parent="p">
                Stop resisting digital payment inovations and start billing your legal clients in a
                faster, frictionless way that boosts satisfaction and streamlines your law firm’s
                operations.
              </Trans>
              <Trans parent="p">
                Use MONEI Pay’s legal billing software to accept more payment methods in person or
                online, send payment requests via SMS, WhatsApp, or email, and save time and money
                on outdated payment processes and hardware.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Get MONEI Pay Now</Trans>
                </SignUpButton>
                <ContactSalesButton />
                <CompatibleWith />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
        </Content>
      </IndexBackground>
      <Content sx={{marginTop: {md: '20px'}}}>
        <Section centered sx={{paddingTop: '20px'}}>
          <Flex direction="column" justifyContent="center" alignItems="center">
            <SectionHeader underline style={{marginTop: '10px', textAlign: 'center'}}>
              <Trans>Get hassle free law firm payment processing</Trans>
            </SectionHeader>
            <p style={{textAlign: 'center'}}>
              <Trans>
                With the right legal billing software, you no longer need a bulky card terminal in
                your law office. Use MONEI Pay to charge your legal clients in seconds from your
                office computer, smartphone, or tablet.
              </Trans>
            </p>
            <CardsContainer sx={{marginTop: '100px'}}>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={cards} alt="" style={{top: -40, width: 80}} />
                <Trans parent="h4">Accept more payment methods</Trans>
                <CardWrapper>
                  <Trans>
                    <InternalPageLink slug="payment-methods/credit-cards">
                      Credit cards
                    </InternalPageLink>
                    ,{' '}
                    <InternalPageLink slug="payment-methods/google-pay">
                      , Google Pay
                    </InternalPageLink>
                    ,{' '}
                    <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                    <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, and{' '}
                    <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={speechbubble} alt="" style={{top: -45, width: 75}} />
                <Trans parent="h4">Send payments requests</Trans>
                <CardWrapper>
                  <Trans>
                    <SupportLink slug="/articles/12253203762449">Request payment</SupportLink> for
                    your legal services via SMS, <InternalPageLink slug="features/whatsapp-payments">WhatsApp</InternalPageLink>, or{' '}
                    <BlogLink slug="email-payment-link">email</BlogLink>
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={money} alt="" style={{top: -50, width: 85}} />
                <Trans parent="h4">Save time and money</Trans>
                <CardWrapper>
                  <Trans>
                    Skip the bulky and expensive POS hardware, take lawyer payments faster, and{' '}
                    <SupportLink slug="/articles/4417709411729">add unlimited users</SupportLink>{' '}
                    for a small fee
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={person} alt="" style={{top: -65, width: 90}} />
                <Trans parent="h4">Build trust</Trans>
                <CardWrapper>
                  <Trans>
                    Add your law firm’s{' '}
                    <SupportLink slug="/articles/8797620433937">logo and branding</SupportLink> to
                    the payment page
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={mobile_payment} alt="" style={{top: -45, width: 115}} />
                <Trans parent="h4">Get paid faster</Trans>
                <CardWrapper>
                  <Trans>
                    Receive{' '}
                    <InternalPageLink slug="bizum-empresas">Bizum payments</InternalPageLink>{' '}
                    instantly for your legal services
                  </Trans>
                </CardWrapper>
              </Card>
              <Card padding="80px 32px 32px 32px">
                <CardImage src={security} alt="" style={{top: -50, width: 60}} />
                <Trans parent="h4">Take secure legal payments</Trans>
                <CardWrapper>
                  <Trans>
                    Process <InternalPageLink slug="pci-dss">PSI-DSS Level 1</InternalPageLink>{' '}
                    guaranteed transactions
                  </Trans>
                </CardWrapper>
              </Card>
            </CardsContainer>
          </Flex>
        </Section>
        <Flex alignItems="center" direction="column">
          <Trans parent="p">
            👇 <Bold>Download MONEI Pay now to streamline payments for your legal services</Bold> 👇
          </Trans>
          <Flex>
            <div style={{marginRight: '16px'}}>
              <DownloadOnAppleStore />
            </div>
            <DownloadOnGooglePlay />
          </Flex>
        </Flex>
      </Content>
      <Background style={{marginTop: '50px'}}>
        <Content>
          <Section sx={{paddingTop: {sm: '100px'}, alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <SectionHeader style={{fontSize: '2rem'}} underline>
                <Trans>How does MONEI Pay’s legal billing software work?</Trans>
              </SectionHeader>
              <Trans parent="p">
                MONEI Pay is a powerful payment solution for law firms. Here’s how you can use it to
                accept secure payments for your legal services:
              </Trans>
              <OrderedList>
                <Trans parent="li">
                  <SignUpLink>Sign up</SignUpLink> for MONEI
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/6167527597457">Download</SupportLink> the MONEI Pay
                  app to your smartphone or tablet
                </Trans>
                <Trans parent="li">
                  <SupportLink slug="/articles/4417709411729/">Add users</SupportLink> to your MONEI
                  account
                </Trans>
                <Trans parent="li">
                  Enter the payment amount to{' '}
                  <SupportLink slug="/articles/4417709618193">
                    create digital QR code payments
                  </SupportLink>
                </Trans>
                <Trans parent="li">Client scans the QR code to pay</Trans>
                <Trans parent="li">
                  Client selects their preferred{' '}
                  <SupportLink slug="/articles/4417709486737">payment method</SupportLink>
                </Trans>
                <Trans parent="li">Client finishes the transaction with the tap of a button</Trans>
              </OrderedList>
              <Trans parent="p">
                If your client isn’t present, you can also use MONEI pay to{' '}
                <SupportLink slug="/articles/12253203762449">send payment requests</SupportLink>{' '}
                <BlogLink slug="email-payment-link">via email</BlogLink>, SMS, or WhatsApp with{' '}
                <InternalPageLink slug="features/pay-by-link">Pay By Link</InternalPageLink>.
              </Trans>
              <SignUpButton variant="dark">
                <Trans>Try MONEI Pay</Trans>
              </SignUpButton>
            </div>
            <RotatedScreenshots />
          </Section>
        </Content>
      </Background>

      <Content>
        <Section reverseOnMobile centered sx={{paddingTop: '40px'}}>
          <QrGroup />
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Simplify payments in your law firm with MONEI Pay</Trans>
            </SectionHeader>
            <Trans parent="p">
              Start now to improve your law firm’s payment processing while saving time and money.
              Create your MONEI account in just a few minutes. Then download the{' '}
              <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
              <IosDownloadLink>iOS</IosDownloadLink>
              mobile payment app on as many devices as you need, or access MONEI Pay from any
              browser at <AnchorLink href="https://pay.monei.com/login">pay.monei.com</AnchorLink>.
            </Trans>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                👈 <Bold>Scan a QR code to experience the payment flow.</Bold>
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Do you have a platform or marketplace for lawyers and law firms?</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Use MONEI Connect to integrate payments and help your users accept more payment
              methods in person or online.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <ButtonLink href="/connect/" variant="dark">
              <Trans>Discover MONEI Connect</Trans>
            </ButtonLink>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%'
            }}
            src={integrated_payments_header_image}
          />
        </GradientBox>
      </Content>
    </>
  );
};

export default RetailPosSytem;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "legal-billing-software"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
